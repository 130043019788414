@import "media-queries.scss";

// Colors:
$white: #eb9ba1;
$black: #010101;
$blue: #00e1e1;
$red: #ff000b;

.header-title {
  color: $white;
  letter-spacing: 10px;
  text-shadow: 4px 2px 0 $red, -4px -2px 0 $blue;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0 1rem 0 0;
  text-align: center;
  margin-bottom: 5px;

  @include mobile {
    font-size: 2.5rem;
    margin-top: 2rem;
  }

  &:hover {
    animation: shadows 0.4s ease-in infinite;
  }
}

@keyframes shadows {
  0%,
  100% {
    text-shadow: 4px 0 0 $red, 0 -4px 0 $blue;
  }

  25% {
    text-shadow: 4px 4px 0 $red, -4px -4px 0 $blue;
  }

  50% {
    text-shadow: 0 4px 0 $red, -4px 0 0 $blue;
  }

  75% {
    text-shadow: 0 0 0 $red, 0 0 0 $blue;
  }
}

.schedule {
  &-block {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $blue;

    @include mobile {
      flex-direction: column;
      width: 100%;
      text-align: center;
    }
  }
}

.date-header {
  font-size: 1.8rem;
  color: #f6868e;
  font-weight: bold;
  text-align: center;
  margin: 10px 0;
  text-shadow: 3px 3px 10px #bb3c44;
  font-size: 35px;
}

.event-box {
  display: flex;
  flex-direction: column;
  background: #330d0fbf;
  border-radius: 20px;
  max-width: 90%;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 95%;
  padding: 5px;
}

.event {
  display: flex;
  justify-content: center;
  background: linear-gradient(to right, #b6323b 25%, rgba(35, 3, 3, 0.022));
  padding: 10px 15px;
  border-radius: 5px;
  margin: 5px 0;
  color: white;
  width: 100%;
  font-weight: bold;
  text-shadow: 1px 1px 5px #f6868e;
}

.event-time {
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 3px;

  @include mobile {
    font-size: 0.7rem;
  }
}

.event-desc {
  flex: 3;
  font-size: 1.2rem;
  font-weight: bold;

  @include mobile {
    font-size: 0.7rem;
  }
}

.eventAlt {
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 5px 0;
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 5px #f6868e;
}
.event-time-alt {
  color: #f6868e;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 3px;

  @include mobile {
    font-size: 0.7rem;
  }
}

.event-desc-alt {
  color: #f6868e;
  flex: 3;
  font-size: 1.2rem;
  font-weight: bold;

  @include mobile {
    font-size: 0.7rem;
  }
}
