@import "_theme.scss";
@import "media-queries.scss";

.contact-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-video {
  position: fixed;
  z-index: -5;
  top: 0;
  left: 0;
  object-fit: cover;
}

.contact-form {
  margin: 5em 0;
  background-color: white;
  width: 500px;

  background: #ffffff;
  border: 1px solid #9c9c9c;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;

  text-align: left;

  padding-top: 40px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;

  @include mobile {
    top: 55%;
    width: 80%;
  }
}

.contact-form > h2 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  left: 10px;
  font-family: Inter;
  line-height: 15px;
  letter-spacing: 0.305em;
  text-transform: uppercase;
  margin-bottom: 10px;

  @include mobile {
    font-size: 1rem;
  }
}

.contact-form > .text-field,
.contact-form > button {
  margin-top: 10px;
}

.contact-form > .text-field {
  width: 100%;
}

.contact-form > .text-field input {
  height: 30px;
}

.contact-form > .text-field > label {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-align: left;
  text-transform: uppercase;
}

.contact-form > button {
  border-radius: 15px;
  height: 50px;
  text-align: center;
  width: 250px;
  left: 50%;
  transform: translate(-50%);

  @include mobile {
    width: 200px;
  }
}
