@import "_theme.scss";
@import "media-queries.scss";

html {
  scroll-behavior: smooth;
}

.home-container {
  text-align: center;
}

.wavy-background-video {
  position: fixed;
  z-index: -5;
  top: 0;
  left: 0;
  object-fit: cover;
  touch-action: none;
  pointer-events: none;
}

.welcome-text {
  position: relative;
  transition: all 0.65s cubic-bezier(0.23, 1, 0.32, 1);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 58px;
  color: #fff;
  text-shadow: 0px 5px 4px rgb(90, 90, 90);
  letter-spacing: 9px;

  margin-top: 8em;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.arrow-homepage {
  position: relative;
  cursor: pointer;

  // center vertically
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  margin-top: 10em;

  @include mobile {
    margin-top: 5em;
  }
}

.white-background {
  position: relative;
  width: 100%;
  background-color: #fff;
  margin-top: 5em;
  margin-bottom: 0;
}

.info {
  //2 items in a row

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 4em;
  margin-right: 4em;
  align-items: center;
  padding-top: 15em;
  margin-bottom: 15em;
}

.about-us {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 0.5em;
  @include mobile {
    text-align: center;
  }
  @include tablet {
    text-align: center;
  }
}

.aboutAndText {
  z-index: 5;
}

.team-text {
  margin: 0;
  left: 160px;
  top: 264px;
  bottom: -23.11%;
  font-style: normal;
  max-width: 12em;
  font-weight: 900;
  font-size: 48px;
  line-height: 58px;
  text-transform: uppercase;
  text-align: left;

  color: #000000;

  @include mobile {
    text-align: center;
    top: 185px;
    left: 52px;
    font-size: 35px;
    line-height: 42px;
  }

  @include tablet {
    text-align: center;
    margin-bottom: 1em;
    top: 208px;
    left: 141px;
    font-size: 48px;
  }
}

.lines-homepage {
  margin-top: 3em;
  position: absolute;
  opacity: 0.6;
  top: 4em;
  width: 100%;
  left: 0px;

  @include tablet {
    margin-top: 10em;
  }
  @include mobile {
    margin-top: 12em;
  }
}

.description-home {
  font-weight: 200;
  font-size: 20px;
  top: 360px;
  left: 946px;
  width: 440px;
  z-index: 5;

  @include mobile {
    margin-top: 2em;
  }
}

.title-content {
  position: relative;
  margin-top: 5em;
  font-weight: 700;
  font-size: 25px;
  letter-spacing: 0.3em;
}

/* What we do / Missions */
.missions-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.mission-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 80px;
}

.mission-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  text-align: left;
}

.mission-heading {
  text-align: left;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.305em;
  text-transform: uppercase;

  color: #000000;
}

.mission-content,
.how-to-join-container .how-to-join-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 125%;
  /* or 29px */

  letter-spacing: 0.1em;

  color: #000000;
}

.mission-images-container img {
  transition: all 0.5s ease;
}

.mission-images-container img:hover {
  filter: brightness(0.8);
}

@include mobile-or-tablet {
  .missions-container {
    align-items: center;
    gap: 80px;
  }

  .mission-container {
    flex-direction: column;
    gap: 0px;
  }

  .mission-heading {
    text-align: center;
  }

  .mission-content {
    text-align: center;
  }
}

/* "How to join" container */

.how-to-join-container {
  width: 70%;
  min-width: fit-content;
  padding-left: 2em;
  padding-right: 2em;
  border: black 1px solid;
  border-radius: 88px;
  margin: auto;
  margin-top: 5em;
}

@include mobile {
  .how-to-join-container {
    min-width: 80%;
  }
}

.how-to-join-container .title-content {
  margin-top: 2em;
  margin-bottom: 2em;
}

.how-to-join-container .discord-logo {
  width: 40px;
  height: 40px;
  margin: auto;
  margin-right: 20px;
}

.how-to-join-container .how-to-join-button {
  margin-top: 2em;
  margin-bottom: 3em;
}

.how-to-join-container .how-to-join-text a {
  color: rgb(0, 135, 238);
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: 500;
}
