@import "media-queries.scss";

/* People */

.peoples-grid {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 50%;
  display: flex;
  flex-wrap: wrap;
  // grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  // grid-gap: 3px;
  justify-items: center;
  justify-content: center;
  padding-top: 20px;
  text-align: center;
}

.people {
  height: 100%;
  margin-bottom: 1em;
}

.people > .image-container {
  width: 100%;
  height: 170px;
  border-radius: 50%;
  overflow: hidden;
}

.people > .image-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.person-names {
  font-weight: 700;
  font-family: "Inter", "sans-serif";
  font-size: 15px;
  letter-spacing: 0.305em;
  text-transform: uppercase;
  margin: 0;
}

.person-position {
  margin: 9px;
  color: #000000;
  font-weight: 300;
  font-size: 13px;
}

.person-description {
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.06em;
}

@include mobile {
  .peoples-grid {
    max-width: fit-content;
  }
}

@include tablet {
  .peoples-grid {
    max-width: 60%;
  }
}
