@import "media-queries.scss";

.linktree {
  &.container {
    font-family: "IBM Plex Mono", monospace;
    width: 100%;
    height: 100%;
    padding-bottom: 10rem;
  }

  &.heading {
    font-weight: 600;
    font-size: 48px;
    letter-spacing: 0.15em;
    margin-top: 3em;
    margin-bottom: 0;
    text-align: center;

    @include mobile {
      font-size: 24px;
    }

    @include tablet {
      font-size: 45px;
    }
  }

  &.club-container {
    padding-top: 5rem;
    max-width: 1350px;
    margin: 0 auto;
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    width: 100%;
    justify-items: center;

    @include mobile {
      grid-template-columns: repeat(1, minmax(250, 1fr));
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    .section {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
        rgb(51, 51, 51) 0px 0px 0px 3px;
      width: 290px;
      height: 450px;
      transition: all 0.2s ease;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      padding: 0 10px 5% 10px;

      .heading {
        font-size: 28px;
      }

      figure {
        img {
          height: auto;
          width: auto;
          object-fit: cover;
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
          filter: grayscale(100%);

          &.cssa {
            max-width: 100px;
          }

          &.dev-club {
            max-width: 200px;
          }

          &.wics {
            max-width: 200px;
          }

          &.cusec {
            max-width: 125px;
          }
        }
      }

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 10px -10px 0px -3px,
          rgb(51, 51, 51) 20px -20px 0px -3px;

        img {
          -webkit-filter: grayscale(0%);
          -moz-filter: grayscale(0%);
          filter: grayscale(0%);
        }
      }
    }
  }
}
