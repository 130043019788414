@import "media-queries.scss";

.prize-categories {
  &-container {
    font-family: "IBM Plex Mono", monospace;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
  }

  &-heading {
    font-weight: 700;
    font-size: 2rem;
    letter-spacing: 0.15em;
    margin-top: 2em;
    margin-bottom: 2em;
    text-align: center;

    @include mobile {
      font-size: 1rem;
    }

    @include tablet {
      font-size: 1.5rem;
    }
  }

  .bold {
    font-weight: 700;
  }

  &.gold-challenge {
    li {
      h5 {
        margin: 0;
      }
      p {
        margin: 0;
        margin-bottom: 0.5rem;
      }
    }
  }
}
