.page-not-found-container {
  text-align: center;
  background-color: white;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}
