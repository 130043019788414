@import "media-queries.scss";

.github {
  &-container {
    font-family: "IBM Plex Mono", monospace;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &-tab-container {
    width: 100%;
    max-width: 1200px;

    img {
      max-width: 750px;
      width: 100%;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  &-table-of-contents {
    position: sticky;
    top: 80px;
    height: fit-content;

    a {
      text-decoration: none;
      color: #000;
    }

    @include tablet {
      display: none;
    }

    @include mobile {
      display: none;
    }
  }

  &-list {
    @include tablet {
      padding: 0;
      width: 100%;

      ul {
        padding: 0;
        width: 100%;
      }
    }

    @include mobile {
      padding: 0;
      width: 100%;

      ul {
        padding: 0;
        width: 100%;
      }
    }
  }

  &-green-text {
    font-weight: 500;
    color: #0b9632;
  }
}

.countdown-container {
  font-family: "IBM Plex Mono", monospace;
  text-align: center;
}

.countdown-text {
  font-size: 24px;
}

.hackathon-started {
  font-size: 24px;
}
