@import "_theme.scss";
@import "media-queries.scss";
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

.devchamps-container {
  font-family: "IBM Plex Mono", monospace;
}

.welcome-part {
  //center horizontally
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.devchamps {
  font-weight: 500;
  font-size: 64px;
  letter-spacing: 0.15em;
  margin-top: 3em;
  margin-bottom: 0;
  @include mobile {
    font-size: 35px;
  }
  @include tablet {
    font-size: 45px;
  }
}

.develop-with-us {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.15em;
}

.intro-text {
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.15em;
  text-align: center;
  margin-top: 3em;
  max-width: 25em;

  @include mobile {
    margin-right: 5%;
    margin-left: 5%;
  }
  @include tablet {
    margin-right: 5%;
    margin-left: 5%;
  }
}

.hall-of-fame {
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15em;
  margin-bottom: 0;
}

.header-devchamps {
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  letter-spacing: 0.15em;
  margin-top: 3em;
  margin-bottom: 0.5em;
}

.under-header-devchamps {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.15em;
  margin-top: 0;
  margin-bottom: 10em;
  color: #dd2727;
  text-align: center;
}

.faq {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0;
}

.question {
  font-weight: 700;
  font-size: 26px;
  letter-spacing: 0.15em;
  color: #fb2e2e;
}

.answer {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.15em;
  text-transform: lowercase;
  line-height: 29px;
}

.questions {
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin-bottom: 0;
  margin-left: 3em;
  margin-right: 3em;
  margin-top: 5em;
}

.question-1,
.question-2,
.question-3,
.question-4 {
  margin-bottom: 2em;
}

.winners {
  width: 100%;
}
