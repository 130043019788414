.footer {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #272729;
  margin-top: 0;
}

.footer-text {
  display: flex;

  position: relative;
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.4em;
  text-transform: uppercase;
  color: #ffffff;
  top: 6em;

  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;

  max-width: 90%;
  margin: auto;
}
