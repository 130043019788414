/* Google fonts to import */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;500;600;700&family=Bebas+Neue&family=Lilita+One&family=Montserrat:wght@300;500;600;700&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.code {
  background-color: rgb(236, 236, 236);
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  border-radius: 8px;
  max-width: 750px;
  width: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}
